.module-header {
    position: relative;

    &--image {}

    &--image-mobile {}

    &--caption {
        position: absolute;
        top: 50%;
        width: 100%;
        text-align: center;
        transform: translateY(-50%);

        &--title {
            width: 100%;
            text-align: center;
            font-size: 5vw;
            font-weight: 400;
            color: $color-text-contrast;
            //text-shadow: 0 0 10px $color-black;
        }
        &--subtitle {
            width: 100%;
            text-align: center;
            font-size: 3vw;
            font-weight: 300;
            color: $color-1;
            //text-shadow: 0 0 10px $color-black;
        }

    }
}

@media screen and (max-width: $size-md-max) {
    .module-header {
        &--caption {
            &--title {
                font-size: 6vw;
            }
            &--subtitle {
                font-size: 4vw;
            }
        }
    }
}


.module-header.ftp {
    .module-header--caption {
        top: 70%;
        h3 {
            font-weight: bold;
            font-size: $font-size-60;
            color: #F7F7F7;
            margin-bottom: 100px;
        }
        a.btn-link {
            width: 335px;
            margin: auto;
        }
    }
    @media screen and (max-width: 1450px) {
        .module-header {
            &--caption {
                &--title {
                    font-size: 2.8rem !important;
                }
            }
        }
        @media screen and (max-width: 1450px) {
            .module-header {
                &--caption {
                    top: 65%;
                    &--title {
                        font-size: 2.5rem !important;
                        margin-bottom: 60px !important;
                    }
                }
            }
            @media screen and (max-width: $size-sm-max) {
                .module-header {
                    &--caption {
                        &--title {
                            font-size: 2.3rem !important;
                            margin-bottom: 50px !important;
                        }
                        a.btn-link {
                            width: 260px;
                        }
                    }
                }
            }
        }
    }
}