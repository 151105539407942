footer {
    min-height: 175px;
    @extend .bg-color-footer;
    @extend .flex;
    @extend .flex-wrap;
    @extend .items-center;

    .footer-wrapper {
        width: 100%;
        padding: 50px 5%;
        @extend .flex;
        @extend .flex-wrap;

        .left-side {
            width: 35%;
            .copyright-mediaweb {
                .copyright {
                    font-weight: normal;
                    font-size: $font-size-14;
                    color: #DEEBEE;
                    opacity: 0.6;
                    margin-bottom: 15px;
                }
            }
        }

        .right-side {
            width: 65%;
            .bloc {
                width: 30%;
                h4 {
                    font-weight: bold;
                    font-size: $font-size-20;
                    color: $color-1;
                    padding-bottom: 25px;
                }
                h5, a {
                    font-family: $font-family-3;
                    font-weight: 500;
                    font-size: $font-size-16;
                    color: #E5E5E5;
                    text-decoration: underline;
                }
            }
        }
        @media screen and (max-width: 1600px){
            .right-side .bloc {
                width: 35%;
            }
            @media screen and (max-width: 1125px){
                flex-direction: column-reverse;
                .left-side {
                    width: 100%;
                }
                .right-side {
                    width: 100%;
                    margin-bottom: 50px;
                    .bloc {
                        width: 30%;
                    }
                }
                @media screen and (max-width: $size-sm-max){
                    .right-side {
                        .bloc {
                            width: 35%;
                        }
                    }
                    @media screen and (max-width: $size-xs-max){
                        .right-side {
                            flex-direction: column;
                            .bloc {
                                width: 100%;
                                h4 {
                                    font-size: 1.2rem;
                                    padding-bottom: 10px;
                                }
                                h5, a {
                                    font-size: 1rem;
                                }
                                &.middle {
                                    padding: 25px 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
