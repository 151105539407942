// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
//@import '../vendors/font-awesome/font-awesome';

/* purgecss start ignore */
@import '../vendors/owl-carousel2/owl.carousel';
// @import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    // to fix parallax bug on iphone...
    .parallax {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu height (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

/** Adds a shadow to the div element **/
.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}


/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or /modules/module-menu-centre.php
/* Or /modules/module-menu-etage.php
 */
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';
// @import 'menu-etage';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';
//@import 'sections/section-temoignages';

@import 'sections/section-bursts';

/*****************************************************/
/* Modules (to use with /modules/module-*-*.php)
/*****************************************************/
//@import 'modules/module-slideshow';
@import 'modules/module-slider-light';
@import 'modules/module-header';
@import 'modules/module-soumission';


a.btn-link {
    background: $color-1;
    width: 260px;
    height: 80px;
    border: 3px solid $color-1;
    border-radius: 10px;
    transition: .5s;
    h5 {
        font-weight: normal;
        font-size: $font-size-18;
        color: $color-white;
        letter-spacing: 3.6px;
        text-transform: uppercase;
    }
    &:hover {
        background: transparent;
        border: 3px solid $color-white;
        transition: .5s;
    }
    @media screen and (max-width: $size-xs-max){
        width: 200px;
    }
}





/*******************************************************************************
* ACCUEIL
*******************************************************************************/

.section_video {
    &__wrapper {
        position: relative;
        video {
            width: 100%;
            height: auto;
        }
        &_overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 3px;
            background:rgba(0, 0, 0, .35);//hsla(9, 100%, 64%, 0.4);//
            &.second {
                bottom: 0 !important;
            }
        }
        &_caption {
            position: absolute;
            top: 50%;
            left: 5%;
            right: 5%;
            h2 {
                font-weight: bold;
                font-size: $font-size-60;
                color: $color-white;
                text-transform: uppercase;
                text-align: center;
                padding-bottom: 50px;
            }
            a.btn-link {
                margin: auto;
            }
            div.main {
                flex: 0 0 auto;
                margin-right: auto;
            }
        }
    }
    @media screen and (max-width: 1450px){
        h2 {
            font-size: 2.8rem;
        }
        @media screen and (max-width: 1024px){
            h2 {
                font-size: 2.4rem;
            }
            @media screen and (max-width: $size-sm-max){
                top: 43%;
                h2 {
                    font-size: 2.25rem;
                }
                @media screen and (max-width: $size-xs-max){
                    h2 {
                        font-size: 2.1rem;
                    }
                    a.btn-link {
                        width: 200px;
                    }
                    @media screen and (max-width: 375px){
                        h2 {
                            font-size: 2rem;
                        }
                        @media screen and (max-width: 360px){
                            h2 {
                                font-size: 1.9rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-image {
    padding: 25px 0 0 0;
    position: relative;
    .left-side {
        background: $color-1;
        position: absolute;
        width: 45%;
        bottom: 0;
        top: 27%;
        padding: 150px 2% 0 5%;
        h3 {
            font-family: $font-family-1;
            font-weight: bold;
            font-size: $font-size-64;
            color: #EDEDED;
            text-transform: initial;
            padding-bottom: 35px;
        }
        p {
            font-size: $font-size-18;
            color: #EDEDED;
        }
        
    }
    .right-side {
        width: 70%;
        margin-left: auto;
    }
    @media screen and (max-width: 1600px){
        .left-side {
            top: 20%;
        }
        @media screen and (max-width: 1510px){
            .left-side {
                h3 {
                    font-size: 3rem;
                }
            }
            @media screen and (max-width: 1415px){
                .left-side {
                    width: 47%;
                    h3 {
                        font-size: 2.8rem;
                    }
                }
                @media screen and (max-width: $size-md-max){
                    .left-side {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding: 0 5%;
                    }
                    @media screen and (max-width: 1218px){
                        .left-side h3 {
                            font-size: 2.7rem;
                        }
                        @media screen and (max-width: 1200px){
                            flex-direction: column-reverse;
                            padding: 50px 5% 0 5%;
                            .left-side {
                                position: initial;
                                width: 100%;
                                padding: 75px 50px;
                            }
                            .right-side {
                                width: 100%;
                            }
                            @media screen and (max-width: $size-xs-max){
                                .left-side {
                                    padding: 50px 25px;
                                    h3 br {
                                        display: none;
                                    }
                                }
                                @media screen and (max-width: 375px){
                                    .left-side {
                                        padding: 50px 15px;
                                        h3 {
                                            font-size: 2.6rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#section-text-carousel {
    padding: 75px 5%;
    .section-text {
        text-align: center;
        padding-bottom: 175px;
        h3 {
            font-weight: 500;
            font-size: $font-size-70;
            color: $color-black;
            text-transform: initial;
            padding-bottom: 15px;
        }
        p {
            width: 80%;
            margin: auto;
        }
    }
    .main-content {
        .item {
            .col-xs-12.txt {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .owl-nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            right: 0;
            top: -75px;
            width: 100%;
            z-index: 1;
            .owl-prev {
                padding-right: 35px;
            }
        }           
    }
    @media screen and (max-width: 1455px){
        .section-text p {
            width: 95%;
        }
        @media screen and (max-width: 1024px){
            .section-text {
                h3 {
                    font-size: 3rem;
                }
                p {
                    width: 100%;
                }
            }
        }
    }
}

/*******************************************************************************
* À PROPOS
*******************************************************************************/

#section-image-text {
    .image-side {
        width: 55%;
        margin-right: 25px;
    }
    .text-side {
        width: 45%;
        background: #00000017;
        padding: 0 5% 0 35px;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 35px;
        }
        p {
            padding-bottom: 100px;
        }
        a.btn-link {
            &:hover {
                border: 3px solid #000;
                h5 {
                    color: #000;
                }
            }
        }
    }
    @media screen and (max-width: 1200px){
        flex-direction: column;
        .image-side {
            width: 100%;
            margin: 0;
        }
        .text-side {
            width: 100%;
            background: #00000017;
            padding: 75px 5%;
        }
    }
}

#section-text-image.propos {
    padding: 75px 0;
    .image-side {
        width: 55%;
        margin-left: 25px;
        background: url("../images/produits_bloc02.jpg") no-repeat;
        background-size: cover;
    }
    .text-side {
        width: 45%;
        background: #00000017;
        padding: 50px 5%;
        h3 {
            font-weight: bold;
            font-size: $font-size-30;
            color: $color-black;
            padding-bottom: 35px;
        }
        .bloc .icon {
            flex: none;
        }
        a.btn-link {
            &:hover {
                border: 3px solid #000;
                h5 {
                    color: #000;
                }
            }
        }
    }
    @media screen and (max-width: 1200px){
        flex-direction: column-reverse;
        .image-side {
            width: 100%;
            margin: 0;
        }
        .text-side {
            width: 100%;
            background: #00000017;
            padding: 75px 5%;
        }
        @media screen and (max-width: $size-xs-max) {
            .image-side {
                background: url("../images/produits_bloc02_m.jpg") no-repeat;
                background-size: cover;
            }
        }
    }
}

/*******************************************************************************
* ACCÈS FTP
*******************************************************************************/











/*******************************************************************************
* COORDONNÉES
*******************************************************************************/

#section-coordonnees {
    margin: -75px 5% 75px 5%;
    padding: 50px;
    position: relative;
    background: $color-white;
    .left-side {
        width: 34%;
    }
    .right-side {
        width: 66%;
        margin-top: 35px;
        .bloc {
            h3 {
                font-family: $font-family-3;
                font-weight: bold;
                font-size: $font-size-26;
                color: $color-black;
                text-transform: initial;
                padding-bottom: 20px;
            }
            h4 {
                font-family: $font-family-3;
                font-weight: normal;
                font-size: $font-size-22;
                color: $color-black;
            }
            h5, a {
                font-family: $font-family-3;
                font-weight: normal;
                font-size: $font-size-18;
                color: $color-black;
            }
        }
    }
    @media screen and (max-width: 1250px) {
        flex-direction: column;
        padding: 50px 25px;
        .left-side {
            width: 100%;
            margin-bottom: 35px;
        }
        .right-side {
            width: 80%;
        }
        @media screen and (max-width: 1024px) {
            .right-side {
                width: 100%;
            }
            @media screen and (max-width: $size-sm-max) {
                margin: 0;
                padding: 75px 5%;
                .left-side {
                    margin-left: -25px;
                }
                .right-side {
                    flex-direction: column;
                    .bloc {
                        h3 {
                            padding-bottom: 10px;
                        }
                        &.middle {
                            padding: 35px 0;
                        }
                    }
                }
                @media screen and (max-width: $size-xs-max) {
                    .left-side {
                        width: 60%;
                    }
                }
            }
        }
    }
}





/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/

@media screen and (max-width: $size-md-max) {
   

}

@media screen and (max-width: $size-sm-max) {
   

}

@media screen and (max-width: $size-xs-max) {
   

}
